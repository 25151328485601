import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';

export const SidenavParamsConfig: SidenavParams = {
  mobileOpened: true,
  footerOffset: false,
  expanded: true,
  disableBackdropClose: false,
};

export const SidenavHeaderConfig = {};

export const SidenavMenuConfig: any[] = [];

export const SidenavFooterConfig = {
  items: [],
};

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ActiveToast } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { delayWhen, retryWhen, tap } from 'rxjs/operators';

import { NotificationService } from '@src/app/modules/cts-notification/services/notification.service';
import { capitalizeFirstLetter } from '@src/app/utils/utils';
import { NotificationComponent } from '@src/app/modules/cts-notification/components/notification.component';
import {
  HTTP_SERVER_ERROR,
  NO_CONNECTION_ERROR,
  SAVE_ERROR_TITLE,
  SAVE_SETTINGS_METHODS,
  SAVE_SUCCESS_TITLE,
  SETTINGS_API_ROUTES,
  SETTING_NAME_PATTERN,
  SYSTEM_ERROR_MESSAGE,
  SYSTEM_ERROR_TITLE,
} from '@src/app/constants/constants';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  systemErrorSnackbar: ActiveToast<NotificationComponent> = null;

  constructor(private notificationService: NotificationService) { }

  /**
   * Case 4xx: Shouldn't retry because unable to success later. Setting API spec that:
   *  - 400: Due to the wrong user input (Ex: Out of range, incorrect data type,...)
   *  - 401: expire token. (Rarely error case on UI)
   *  - 404: Resource not found. (Rarely error case on UI)
   *  - 422: Wrong entity body. (Rarely error case on UI)
   *
   * Case 5xx: Should retry because having able to success later.
   *  - method POST/PATCH: retry with exactly the previous request.
   *  - method GET or others: retry by reload the page.
   *
   * Look at the discussing/mapping of Threshold Settings UI for more details:
   * https://lucid.app/lucidspark/62d73c61-4edf-4f60-b27e-9b09f5729080/edit?invitationId=inv_e828e3d3-caab-4805-9ddd-ad1a2fca9fba&page=0_0#
   *
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (SETTINGS_API_ROUTES.find((route) => request.url.includes(route))) {
      return next.handle(request).pipe(
        retryWhen((error) => error.pipe(
          delayWhen((error) => {
            if (error instanceof HttpErrorResponse) {
              // Save settings
              if (this.isSaveSettingRequest(request)) {
                const isRetryBtnShow = error.status === NO_CONNECTION_ERROR || error.status >= HTTP_SERVER_ERROR;
                /**
                 * Retry when Retry button clicked before snackbar hidden
                 * The 4xx error snackbar has no Retry button
                 * The 5xx error snackbar alway has Retry button
                 */
                return this.showSaveErrorSnackbar(SAVE_ERROR_TITLE, this.buildSaveErrorMessage(request), { isRetryBtnShow });
              }

              // Get settings
              if (this.systemErrorSnackbar) {
                // A system error is being showed. Make sure only one system error should be showed
                return throwError(error);
              }

              /**
               * Retry when Retry button clicked before snackbar hidden
               * The 5xx error snackbar alway has Retry button
               */
              return this.showSystemErrorSnackbar(SYSTEM_ERROR_TITLE, SYSTEM_ERROR_MESSAGE);
            }

            // Throw error to release "retryWhen" operator
            return throwError(error);
          })),
        ),
        /**
         * Temporarily handle for show success snackbar when POST/PATCH settings success.
         * For now, Figma/LucidChart haven't yet introduced the successful snackbar after POST/PATCH settings.
         * TODO: Need to ask @Morgan for confirm to add an official JIRA ticket.
         *
         */
        tap((response) => {
          if (response instanceof HttpResponse && this.isSaveSettingRequest(request)) {
            this.notificationService.showSuccessMessage(SAVE_SUCCESS_TITLE, this.buildSaveSuccessMessage(request));
          }
        })
      );
    }

    return next.handle(request);
  }

  private showSaveErrorSnackbar(title: string, message: string, config: { isRetryBtnShow: boolean }) {
    const { isRetryBtnShow } = config;

    if (isRetryBtnShow) {
      // Case retry showing
      const retryClick$ = new Subject<boolean>();
      const toast = this.notificationService.showErrorMessage(title, message, {
        timeOut: 15000,
        payload: {
          // click retry
          retryHandler: () => {
            retryClick$.next(true);
            retryClick$.complete();
          },
        },
      });
      const subscription = toast.onHidden.subscribe(() => {
        // No click retry on snackbar. Auto dismiss snackbar happen.
        retryClick$.error({});
        subscription.unsubscribe();
      });

      return retryClick$.asObservable();
    } else {
      // Case No retry showing
      this.notificationService.showErrorMessage(title, message, {
        timeOut: 15000,
      });

      return throwError({});  
    }
  }

  private showSystemErrorSnackbar(title: string, message: string) {
    const retryClick$ = new Subject<boolean>();
    this.systemErrorSnackbar = this.notificationService.showErrorMessage(title, message, {
      timeOut: 15000,
      toastClass: 'ngx-toastr system-error-snackbar',
      payload: {
        // click retry
        retryHandler: () => {
          window.location?.reload();
        },
      },
    });

    const subscription = this.systemErrorSnackbar.onHidden.subscribe(() => {
      // no click retry on snackbar. Auto dismiss snackbar happen.
      subscription.unsubscribe();
      this.systemErrorSnackbar = null;
      retryClick$.error({});
    });
    return retryClick$.asObservable();
  }

  private isSaveSettingRequest(request: HttpRequest<unknown>) {
    return SAVE_SETTINGS_METHODS.includes(request.method) && !!(request.body['name'] || '').match(SETTING_NAME_PATTERN);
  }

  private buildSaveSuccessMessage(request: HttpRequest<unknown>) {
    const description = `threshold have been updated.`;
    return this._buildMessageForSave(request, description);
  }

  private buildSaveErrorMessage(request: HttpRequest<unknown>) {
    const description = `threshold could not be updated.`;
    return this._buildMessageForSave(request, description);
  }

  private _buildMessageForSave(request: HttpRequest<unknown>, desciption: string) {
    const { name, severity } = this._extractNameAndSeverity(request);

    return capitalizeFirstLetter(`${severity} ${name} ${desciption}`);
  }

  private _extractNameAndSeverity(request: HttpRequest<unknown>) {
    const [_prefix, name, severity] = request.body['name'].match(SETTING_NAME_PATTERN);
    return {
      name: name.split('_').join(' '), // Replace "_" by " ".
      severity: severity,
    };
  }
}

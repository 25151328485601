<mat-expansion-panel [togglePosition]="'top'" [expanded]="isExpanded" [disabled]="isDisabled" (opened)="expansionChange.emit(true)" (closed)="expansionChange.emit(false)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <ng-content select="[header-expansion-title]"></ng-content>
    </mat-panel-title>
    <mat-panel-description>
      <ng-content select="[header-expansion-description]"></ng-content>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <ng-content select="[expansion-panel-content]"></ng-content>
  </ng-template>
</mat-expansion-panel>

import { Component, Input, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { isEmpty, isEqual } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogComponent } from '../dialog/dialog.component';
import { DynamicFormControl, EventView } from '@src/app/models/ui-layout.model';
import { noChangesValidator, requiredValidator } from '@src/app/utils/utils';
import { UpdateSettingsPayload } from '@src/app/models/settings.model';
import { AppState, SaveCompanySettings } from '@src/app/app.state';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit, OnDestroy {
  @Select(AppState.getUserPermission) userPermission$: Observable<Record<string, boolean>>;

  @Input() event: EventView;
  @Output() expansionChange = new EventEmitter<boolean>();

  formGroup: FormGroup;
  defaultSettingValues: Record<string, string> = {};
  currentValues: Record<string, DynamicFormControl> = {};
  userPermission: Record<string, boolean>;
  isSaving: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private dialog: MatDialog,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.formGroup.controls.useDefaultSettings.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe((value: boolean) => {
      if (value) {
        this.formGroup.patchValue(this.defaultSettingValues);
      }
    });
    this.userPermission$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(userPermission => this.userPermission = userPermission);
  }

  onSubmit() {
    this.openSaveConfirmDialog();
  }

  openSaveConfirmDialog() {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Update Threshold',
        messages: [
          `All incoming data for your entire company's alerts and reports will be processed using the updated threshold. Data that has already been processed and displayed will not be changed.`,
          'Please wait up to 15 minutes for the updated threshold to take effect.',
          'Are you sure you would like to proceed?',
        ],
        cancelText: 'Cancel',
        acceptText: 'Confirm',
        onAccept: () => {
          this.saveCompanySettings();
        },
      },
      panelClass: 'update-settings-confirmation-dialog',
      maxWidth: '600px',
    });
  }

  saveCompanySettings() {
    this.isSaving = true;

    const { useDefaultSettings, ...updatedValues } = this.formGroup.getRawValue();
    const payload: UpdateSettingsPayload = {
      currentValues: this.currentValues,
      updatedValues: updatedValues,
    };

    this.store.dispatch(new SaveCompanySettings(payload)).subscribe(
      () => {},
      () => {},
      () => {
        this.isSaving = false;
      }
    );
  }

  onCancel() {
    // Restore to initial value
    this.formGroup.reset();
  }

  private buildForm() {
    const settingValues: Record<string, string> = {};
    this.event.thresholdsView.forEach(({ settingsView }) => {
      settingsView.forEach(({ formControl }) => {
        if (formControl) {
          const { key, value, defaultValue, validators } = formControl;
          settingValues[key] = value;
          this.defaultSettingValues[key] = defaultValue;
          this.currentValues[key] = formControl;
        }
      });
    });

    const formControls = { ...settingValues, useDefaultSettings: isEqual(settingValues, this.defaultSettingValues) };
    this.formGroup = this.formBuilder.group(formControls, { validators: [requiredValidator(), noChangesValidator(settingValues)] });
  }

  // A configurable event is when has a least one setting formControl existing.
  get isConfigurableEvent() {
    return !!this.event.thresholdsView.find(({ settingsView }) => settingsView.find(({ formControl }) => !!formControl));
  }

  get isSaveButtonDisabled() {
    return this.formGroup?.pristine || this.formGroup?.invalid || this.isSaving;
  }

  get isCancelButtonDisabled() {
    return this.formGroup?.pristine || this.isSaving;
  }

  get lastUpdatedTime() {
    return this.event.modified ? moment(this.event.modified).format('M/D/YY') : null;
  }

  get isSettingFieldDisabled() {
    return !this.userPermission?.manage || this.formGroup?.controls.useDefaultSettings.value;
  }

  get isNoUserPermission() {
    return isEmpty(this.userPermission);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

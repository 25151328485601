import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { SettingsApiService } from './services/settings-api.service';
import { Setting, SettingsDefinition, UpdateSettingsPayload } from './models/settings.model';
import { Category } from './models/ui-layout.model';

export class GetCompanySettings {
  static readonly type = '[App] Get Company Settings';
  constructor(public companyId: string) { }
}

export class GetConfigurationLayoutUI {
  static readonly type = '[App] Get Configuration Layout UI';
  constructor() { }
}

export class SaveCompanySettings {
  static readonly type = '[App] Save Company Settings';
  constructor(public payload: UpdateSettingsPayload) { }
}

export class SetSelectedCompany {
  static readonly type = '[App] Set Selected Company';
  constructor(public companyId: string) { }
}

export class SetUserPermission {
  static readonly type = '[App] Set User Permission';
  constructor(public perm: Record<string, boolean>) { }
}

export class AppStateModel {
  thresholdCategories: Category[];
  settingsDefinitionList: SettingsDefinition[];
  settingList: Setting[];
  companyId: string;
  userPermissions: Record<string, boolean>;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    thresholdCategories: [],
    settingsDefinitionList: [],
    settingList: [],
    companyId: '',
    userPermissions: {},
  },
})
@Injectable()
export class AppState {
  constructor(
    private store: Store,
    private settingsApiService: SettingsApiService,
  ) { }

  @Selector()
  static getSettings(state: AppStateModel) {
    if (state.settingsDefinitionList.length === 0) {
      return {};
    }

    return state.settingsDefinitionList.reduce((acc, { name, type, typeInfo, created, modified }) => {
      const setting = state.settingList.find(item => item.name === name) || {} as Setting;
      const minField = (typeInfo.min || typeInfo.min === 0) ? { min: typeInfo.min } : {};
      const maxField = typeInfo.max ? { max: typeInfo.max } : {};
      const optionsField = typeInfo.options ? { options: typeInfo.options } : {};
      return {
        ...acc,
        [name]: {
          id: setting.id,
          value: setting.value,
          defaultValue: typeInfo?.default,
          type: type,
          modified: setting.modified || setting.created || modified || created,
          ...minField,
          ...maxField,
          ...optionsField,
        },
      };
    }, {});
  }

  @Selector()
  static getConfigurationLayoutUI(state: AppStateModel) {
    return state.thresholdCategories;
  }

  @Selector()
  static getCompanyId(state: AppStateModel) {
    return state.companyId;
  }

  @Selector()
  static getUserPermission(state: AppStateModel) {
    return state.userPermissions;
  }

  @Action(GetCompanySettings)
  getCompanySettings({ patchState }: StateContext<AppStateModel>, action: GetCompanySettings) {
    return this.settingsApiService.getCompanySettings(action.companyId).pipe(
      tap(([settingsDefinitionList, settingList]) => {
        patchState({
          settingsDefinitionList,
          settingList,
        });
      }),
    );
  }

  @Action(GetConfigurationLayoutUI)
  getConfigurationLayoutUI({ patchState }: StateContext<AppStateModel>) {
    return this.settingsApiService.getConfigurationLayoutUI().pipe(
      tap((thresholdCategories) => {
        patchState({ thresholdCategories });
      }),
    );
  }

  @Action(SaveCompanySettings)
  saveCompanySettings({ dispatch, getState }: StateContext<AppStateModel>, action: SaveCompanySettings) {
    const companyId = getState().companyId;
    return this.settingsApiService.saveCompanySettings(action.payload, companyId).pipe(
      switchMap((responseList) => {
        if (responseList.find((result) => !!result)) {
          return dispatch(new GetCompanySettings(companyId));
        }

        return of(responseList);
      })
    );
  }

  @Action(SetSelectedCompany)
  setSelectedCompany({ patchState }: StateContext<AppStateModel>, action: SetSelectedCompany) {
    patchState({
      settingsDefinitionList: [],
      settingList: [],
    });

    patchState({
      companyId: action.companyId,
    });
  }

  @Action(SetUserPermission)
  setUserPermission({ getState, patchState }: StateContext<AppStateModel>, action: SetUserPermission) {
    patchState({
      userPermissions: action.perm,
    });
  }
}

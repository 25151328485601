<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start">
  <div class="dialog-title">{{ title }}</div>
</div>
<mat-dialog-content>
  <div class="dialog-content">
    <div class="part-of-message-content" *ngFor="let part of confirmMessage">{{ part }}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button 
    class="cancel-button"
    mat-dialog-close
    appThrottleClick
    (throttledClick)="handleCancel()"
  >{{ cancelText }}</button>
  <button mat-button 
    class="accept-button"
    mat-dialog-close
    appThrottleClick
    (throttledClick)="handleAccept()"
  >{{ acceptText }}</button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss'],
})
export class ThresholdComponent {
  @Input() title: string = '';
  @Input() icon: string = '';
}

<div class="container">
  <div class="page-title">Threshold Settings</div>
  <div class="page-description">
    Critical and minor thresholds categorize data for your entire company and lay the foundation for your alerts. For alerts management, please visit our dedicated
    <a id="alert-url" [href]="alertPageUrl">Alerts Page</a>.
  </div>
  <ng-container *ngIf="categoriesView$ | async as categories; else skeletonList">
    <ng-container *ngTemplateOutlet="categoryList; context: { $implicit: categories }"></ng-container>
  </ng-container>
</div>

<ng-template #categoryList let-categories>
  <div class="categories">
    <section class="category-section" *ngFor="let categoryView of categories">
      <div class="category-title">{{ categoryView.title }}</div>
      <mat-accordion class="events expansion-panel-container" displayMode="default" multi>
        <div class="event event-expansion" *ngFor="let event of categoryView.eventsView">
          <app-event [event]="event" (expansionChange)="expansionChange(event.name, $event)"></app-event>
        </div>
      </mat-accordion>
    </section>
  </div>
</ng-template>

<ng-template #skeletonList>
  <div class="categories">
    <section class="category-section" *ngFor="let skeleton of displayedLayoutUI$ | async">
      <div class="category-title">{{ skeleton.title }}</div>
      <div *ngFor="let event of skeleton.events">
        <ngx-skeleton-loader
          [theme]="{
            'border-radius': '5px',
            'height': '81.5px',
            'background-color': '#E3E3E3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </section>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

import { NotificationComponent } from '../components/notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastService: ToastrService) { }

  showSuccessMessage(title: string, message: string, config: Partial<IndividualConfig> = {}): ActiveToast<NotificationComponent> {
    return this.toastService.success(message, title, config);
  }

  showErrorMessage(title: string, message: string, config: Partial<IndividualConfig> = {}): ActiveToast<NotificationComponent> {
    /**
     * The way to display snackbar including button 'Try Again':
     *  ------------------------------------------------------------------
     *    this.showErrorMessage('title', 'message', {
     *       payload: {
     *          retryHandler: () => { 
     *            // add logic retry here 
     *          }
     *       }
     *    })
     *  ------------------------------------------------------------------
     */
    return this.toastService.error(message, title, config);
  }

  clear(toastId?: number) {
    this.toastService.clear(toastId)
  }
}

<ng-container [formGroup]="formGroup">
  <ng-container [ngSwitch]="typeControl">
    <ng-container *ngSwitchCase="'number'">
      <ng-container *ngTemplateOutlet="inputField; context: { $implicit: formControlNameKey, typeControl: 'number', isDisabled: isDisabled }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'integer'">
      <ng-container *ngTemplateOutlet="inputField; context: { $implicit: formControlNameKey, typeControl: 'number', isDisabled: isDisabled }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'string'">
      <ng-container *ngTemplateOutlet="inputField; context: { $implicit: formControlNameKey, typeControl: 'text', isDisabled: isDisabled }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'choice'">
      <ng-container *ngTemplateOutlet="dropdownField; context: { $implicit: formControlNameKey, options: options, isDisabled: isDisabled }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'boolean'">
      <ng-container *ngTemplateOutlet="checkboxField; context: { $implicit: formControlNameKey, isDisabled: isDisabled }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'date-time'">
      <ng-container *ngTemplateOutlet="dateTimeField"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div>TBD</div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #inputField let-controlName let-type="typeControl" let-isDisabled="isDisabled">
  <mat-form-field class="inline dynamic-control-row {{ type }} {{ controlName }}" appearance="outline" [class.readonly-input-field]="isDisabled">
    <input [type]="type" [formControlName]="controlName" [readonly]="isDisabled" matInput />
  </mat-form-field>
</ng-template>

<ng-template #dropdownField let-controlName let-options="options" let-isDisabled="isDisabled">
  <mat-form-field class="inline dynamic-control-row" appearance="outline" [class.mat-form-field-disabled]="isDisabled">
    <mat-select [formControlName]="controlName" [disabled]="isDisabled">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #checkboxField let-controlName let-label="label" let-isDisabled="isDisabled">
  <mat-checkbox [formControlName]="controlName" [disabled]="isDisabled">{{ label }}</mat-checkbox>
</ng-template>

<ng-template #dateTimeField>
  <!-- TODO: Update DateTime UI when introduced -->
  <span>DateTime field is comming up...</span>
</ng-template>

<div ngClass.gt-sm="app-container-desktop"
  ngClass.lt-md="app-container-mobile"
  fxLayout="column"
  fxFill>
  <zui-sidenav [sidenavParams]="sidenavParams"
    [header]="sidenavHeader"
    (emitSelectedCompany)="setCurrentCompanyByFormSelection($event)">
    <div *ngIf="auth.isAuthenticated$ | async"
      class="sidenav-content-container app-bg-color"
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="3rem">
      <div fxLayout="column" fxLayoutAlign="start stretch" class="page-body">
        <zui-sidenav-header class="header-bar-sidenav"
          (menuToggled)="onSideNavMobileMenuButtonToggled($event)"></zui-sidenav-header>
        <div class="content-container">
          <router-outlet></router-outlet>
        </div>
        <zui-footer-component></zui-footer-component>
      </div>
    </div>
  </zui-sidenav>
</div>

<div class="toast-custom-container">
  <!-- Show the X icon to close snackbar based on configuration -->
  <button *ngIf="options.closeButton" (click)="remove()" type="button" class="toast-close-button" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="main-content-container">
    <!-- Show the title of snackbar -->
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
    </div>
    <!-- Show the message of snackbar. Support both innerHTML and innerText -->
    <ng-container *ngIf="message">
      <div *ngIf="options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
      <div *ngIf="!options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </ng-container>
  </div>
  <!-- Show the button "Try Again" based on configuration -->
  <div *ngIf="options.payload?.retryHandler" class="retry-content-container">
    <div (click)="options.payload?.retryHandler()" class="retry-button">Try again</div>
  </div>
  <!-- Show the progress to close snackbar based on configuration -->
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-expansion-panel',
  templateUrl: './custom-expansion-panel.component.html',
  styleUrls: ['./custom-expansion-panel.component.scss']
})
export class CustomExpansionPanelComponent {
  @Input() isExpanded: boolean;
  @Input() isDisabled: boolean;
  @Output() expansionChange = new EventEmitter<boolean>();
}

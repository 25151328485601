// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  appUrl: 'settings.dev.zonarsystems.net',
  apiBase: {
    url: 'https://api.zonarsystems.net/core/v1/settings',
  },
  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net/core/v1',
  },
  userPreferenceApiBase: {
    url: 'https://user-alert-preference-api-aag-355.dev.zonarsystems.net',
  },
  externalLinks: {
    alertPage: 'https://alertmanagement.dev.zonarsystems.net/',
  },
  authEnv: 'development',
  auth: {
    clientID: 'IPLO6Kuvb2u0hYxvY9WKhUO3Us6Tmd1b',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: '93818bc1-8c96-4e62-a460-232b7c679239',
    defaultZonarRole: '602ee7e2-a2ec-4dfa-92d5-53c06796ef20',
    useRefreshTokens: true,
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '4-KiGGwpt35kxwSPey_o-w',
    environment: 'env-3',
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null,
  },
  datadog: {
    enabled: true,
    applicationId: 'c600f2b7-494c-4e18-afc6-439707bcc61c',
    clientToken: 'pub9209625fca6265b4ba1bae7620e0f7b4',
    siteUrl: 'us5.datadoghq.com',
    tagEnvironment: 'development',
  },
  i18n: {
    supportedLanguages: ['en', 'en-GB', 'de', 'es', 'fr', 'it'],
    defaultLanguage: 'en',
  },
  devCycle: {
    clientId: 'dvc_client_e53775ea_d05c_484a_adc6_80e4d946ff92_be0127b',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

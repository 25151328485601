import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, merge } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ETenantType, PermissionsService } from '@zonar-ui/auth';

import { AppState, SetUserPermission } from '@src/app/app.state';
import { environment } from '@src/environments/environment';

const AUTH_PERMISSIONS = {
  MANAGE: 'manage:settings',
  VIEW: 'view:settings',
};

const adminPermPatternBuilder = (companyId: string) => `:${companyId}:::${AUTH_PERMISSIONS.MANAGE}`;
const viewerPermPatternBuilder = (companyId: string) => `:${companyId}:::${AUTH_PERMISSIONS.VIEW}`;

@Injectable({
  providedIn: 'root',
})
export class AuthWiringService {
  constructor(
    private permissionsService: PermissionsService,
    private store: Store,
  ) { }

  initialize() {
    const currentCompany$ = this.store.select(AppState.getCompanyId).pipe(filter(companyId => !!companyId));

    currentCompany$.pipe(
      tap((companyId) => this.permissionsService.setCurrentCompanyContextById(companyId)),
      switchMap((companyId) => combineLatest([
        this.permissionsService.hasPermission(adminPermPatternBuilder(companyId)),
        this.permissionsService.hasPermission(viewerPermPatternBuilder(companyId)),
      ])
    )).subscribe(([isAdmin, isViewer]) => {
      // Admin role is found (by ZonarAdmin or CompanyAdmin).
      if (isAdmin) {
        this.store.dispatch(new SetUserPermission({ manage: true }));
        return;
      }

      // Viewer role is found
      if (isViewer) {
        this.store.dispatch(new SetUserPermission({ viewer: true }));
        return;
      }

      // Empty roles
      this.store.dispatch(new SetUserPermission({}));
    });

  }

  getEmptyPerm() {
    const emptyCompany$ = combineLatest([
      this.permissionsService.getIsZonarUser().pipe(filter(isZonarUser => isZonarUser === false)),
      this.permissionsService.getCompanyMap().pipe(filter(companyMap => Boolean(companyMap) && Object.keys(companyMap).length <= 0)),
    ]);

    const emptyRoleZonarUser$ = this.permissionsService.getIsZonarUser().pipe(
      filter(isZonarUser => isZonarUser === true),
      switchMap(() => this.hasZonarUserRoles()),
      filter((result) => result === false),
    )

    const emptyPerms$ = this.permissionsService.getIsFetchPermsSuccess().pipe(
      filter((result) => result === false),
    );

    return merge(
      emptyCompany$,
      emptyPerms$,
      emptyRoleZonarUser$,
    )
  }

  hasZonarUserRoles() {
    return combineLatest([
      this.permissionsService.getUserGroupPolicies().pipe(filter(userPolicies => Array.isArray(userPolicies))),
      this.permissionsService.getUserProfiles().pipe(filter(userProfiles => Array.isArray(userProfiles))),
    ]).pipe(
      map(([userPolicies, userProfiles]) => {
        if (userPolicies.length > 0) {
          // Policies has higher priority than profiles
          return userPolicies
            .filter(policy => policy?.policy.grants.find(grant => grant.application.id === environment.auth.applicationId))
            .filter(policy => policy.tenant.scope.type === ETenantType.ALL_COMPANIES).length > 0;
        }

        if (userProfiles.length > 0) {
          // Back to checking with profiles after known that policies is empty
          return !!userProfiles
            .filter(profile => profile?.applicationId === environment.auth.applicationId)
            .find(profile => profile?.status === 'ACTIVE' && !profile.companyId && !profile.divisions.length);
        }
        
        // Missing zonar roles on CTS application. Seems to a mistake when setup this Zonar account to this application.
        // Still show error snackbar
        return false
      })
    )
  }
}

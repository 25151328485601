import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, from, of } from 'rxjs';
import { bufferCount, catchError, concatMap, mapTo, reduce, retry } from 'rxjs/operators';

import { environment } from '@src/environments/environment';
import { Setting, SettingBodyModel, SettingsDefinition, UpdateSettingsPayload } from '../models/settings.model';
import { ALLOWED_CONCURRENTLY_REQUESTS_NUMBER, httpRetryCount } from '@src/environments/shared';
import { UI_CONFIGS } from '../config/layout-ui.config';
import { Category } from '../models/ui-layout.model';
import { OWNER_TYPE_OPTIONS } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {
  endpoint = `${environment.coreEntityApiBase.url}`;

  constructor(private httpClient: HttpClient) { }

  public getCompanySettings(companyId: string): Observable<[SettingsDefinition[], Setting[]]> {
    return forkJoin([
      this.getSettingsDefinitionList(),
      this.getSettingList(companyId),
    ]);
  }

  public getConfigurationLayoutUI(): Observable<Category[]> {
    return of(UI_CONFIGS);
  }

  private getSettingsDefinitionList(): Observable<SettingsDefinition[]> {
    return this.httpClient.get<SettingsDefinition[]>(`${this.endpoint}/definitions?applicationId=${environment.auth.applicationId}&status=ACTIVE`).pipe(
      retry(httpRetryCount),
      catchError(() => of([])),
    );
  }

  private getSettingList(companyId: string): Observable<Setting[]> {
    return this.httpClient.get<Setting[]>(`${this.endpoint}/settings?ownerType=COMPANY&ownerId=${companyId}&status=ACTIVE&applicationId=${environment.auth.applicationId}`).pipe(
      retry(httpRetryCount),
      catchError(() => of([])),
    );
  }

  public saveCompanySettings(updateSettingData: UpdateSettingsPayload, companyId: string) {
    const requests$ = this.buildListOfRequest(updateSettingData, companyId);
    return from(requests$).pipe(
      bufferCount(ALLOWED_CONCURRENTLY_REQUESTS_NUMBER),
      concatMap((chunkRequest) => forkJoin(chunkRequest)),
      reduce((acc, chunkResponse) => [...acc, ...chunkResponse], []),
    );
  }

  private buildListOfRequest({ currentValues, updatedValues }: UpdateSettingsPayload, companyId: string) {
    return Object.values(currentValues).reduce((requests, { id, key, value }) => {
      if (value === updatedValues[key]) {
        // Case: ignore with fields value hasn't updated
        return requests;
      }

      if (id) {
        // Case: currentValue != updatedValue: use method PATCH
        requests.push(this.patchSetting(id, key, updatedValues[key]));
      } else {
        // Case: NON settingId: use method POST
        requests.push(this.postSetting(key, updatedValues[key], companyId));
      }

      return requests;
    }, []);
  }

  private postSetting(settingName: string, newValue: string, companyId: string): Observable<Setting> {
    const body: SettingBodyModel = {
      name: settingName,
      value: newValue,
      ownerId: companyId,
      ownerType: OWNER_TYPE_OPTIONS.COMPANY,
      applicationId: environment.auth.applicationId,
    };

    return this.httpClient.post<Setting>(`${this.endpoint}/settings`, body).pipe(
      catchError(() => of(null))
    );
  }

  private patchSetting(settingId: string, settingName: string, newValue: string): Observable<{}> {
    const body: SettingBodyModel = {
      name: settingName,
      value: newValue,
    };

    return this.httpClient.patch<{}>(`${this.endpoint}/settings/${settingId}`, body).pipe(
      mapTo({}),
      catchError(() => of(null))
    );
  }
}

import { Category } from '../models/ui-layout.model';

export const UI_CONFIGS: Category[] = [
  {
    title: 'Performance',
    events: [
      {
        name: 'State of Charge (Electric Vehicles)',
        description: `State of Charge events occur when an electric vehicle's charge goes below a set percentage.`,
        footer: 'Note: Events only apply to heavy-duty electric vehicles.',
        lastUserSettingName: 'company_threshold_state_of_charge_(electric_vehicles)_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when state of charge goes below',
                endDescription: 'percent',
                settingName: 'company_threshold_low_soc_critical',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when state of charge goes below',
                endDescription: 'percent',
                settingName: 'company_threshold_low_soc_minor',
              },
            ],
          },
        ],
      },
      {
        name: 'Vehicle Idling',
        description: `Idling events occur when a vehicle has been idling beyond the idle threshold.`,
        footer: '',
        lastUserSettingName: 'company_threshold_idle_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Creates a critical event when idle goes above',
                endDescription: 'minutes',
                settingName: 'company_threshold_idle_critical',
              },
            ],
          }
        ],
      },
    ],
  },
  {
    title: 'Maintenance',
    events: [
      {
        name: 'Vehicle Battery',
        description: 'Vehicle Battery events occur when Zonar detects low battery voltage on your vehicle.',
        lastUserSettingName: 'company_threshold_vehicle_battery_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when voltage is below',
                endDescription: 'volts',
                settingName: 'company_threshold_vehicle_battery_critical',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when voltage is below',
                endDescription: 'volts',
                settingName: 'company_threshold_vehicle_battery_minor',
              },
            ],
          },
        ],
      },
      {
        name: 'Engine Fault',
        description: 'Engine Fault events occur when one or more diagnostic trouble code events trigger.',
        lastUserSettingName: 'company_threshold_engine_fault_last_user',
        thresholds: [
          {
            name: 'Critical',
            icon: 'info',
            settings: [
              {
                startDescription: 'Create a critical event when Check Engine lights up',
              },
            ],
          },
          {
            name: 'Minor',
            icon: 'report_problem',
            settings: [
              {
                startDescription: 'Create a minor event when the Check Engine lights and Malfunction Indicator Lamp (MIL) are up',
              },
            ],
          },
        ],
      },
    ],
  },
];

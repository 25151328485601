import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { first } from 'rxjs/operators';

import { PermissionsService } from '@zonar-ui/auth';

import { environment } from '../../environments/environment';
import { appVersion } from '../../environments/shared';


@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  private user: User;

  constructor(private permissions: PermissionsService) {
    if (this.isRumEnabled()) {
      const { applicationId, clientToken, siteUrl, tagEnvironment } = environment.datadog;
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        applicationId,
        clientToken,
        env: tagEnvironment,
        service: 'threshold-settings-ui',
        version: appVersion,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        silentMultipleInit: true,
        trackUserInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/],
        site: siteUrl,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogLogs.init({
        clientToken,
        env: tagEnvironment,
        service: 'threshold-settings-ui',
        silentMultipleInit: true,
        site: siteUrl,
        forwardErrorsToLogs: false,
        sampleRate: 100,
      });

      this.permissions.getUser().pipe(first((users) => Boolean(users))).subscribe((user) => {
        this.user = user;
        datadogRum.setUser({
          id: this.user.id,
          email: this.user.email,
          sub: this.user.sub,
        });
      });
    }
  }

  isLocalDevEnv() {
    return globalThis?.location?.origin?.includes('localhost');
  }

  isRumEnabled() {
    return environment.datadog.enabled && !this.isLocalDevEnv();
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    if (this.isRumEnabled()) {
      datadogRum.startSessionReplayRecording();
    }
  }

  stopSessionReplayRecording(): void {
    if (this.isRumEnabled()) {
      datadogRum.stopSessionReplayRecording();
    }
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (this.isRumEnabled()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }
}

export enum STATUS_OPTIONS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
  ANY = 'ANY',
}

export enum OWNER_TYPE_OPTIONS {
  COMPANY = 'COMPANY',
  USERPROFILE = 'USERPROFILE',
  USER = 'USER',
}

export enum TYPE_OPTIONS {
  NUMBER = 'number',
  INTEGER = 'integer',
  DATE_TIME = 'date-time',
  BOOLEAN = 'boolean',
  STRING = 'string',
  CHOICE = 'choice',
}

export const SETTING_NAME_PATTERN: RegExp = /company_threshold_(.*)_(minor|critical)/;
export const HTTP_SERVER_ERROR: number = 500;
export const NO_CONNECTION_ERROR: number = 0;

export const SETTINGS_API_ROUTES: string[] = ['/settings', '/definitions'];
export const SAVE_SETTINGS_METHODS: string[] = ['POST', 'PATCH'];

export const SAVE_ERROR_TITLE: string = `Error saving threshold settings`;
export const SAVE_SUCCESS_TITLE: string = `Success saving threshold settings`;
export const SYSTEM_ERROR_TITLE: string = `An error has occurs`;

export const SYSTEM_ERROR_MESSAGE: string = `<div class="chunk">We were unable to load threshold settings due to a connection issue. Please try again.</div><div class="chunk">If the issue persists,
please contact <a href='https://www.zonarsystems.com/contact/'>Zonar Customer Service.</a></div>`;

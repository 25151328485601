import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

type CallBackFunction = () => void;

export interface GenericDialogData {
  cancelText: string;
  acceptText: string;
  title: string;
  messages?: string | string[];
  onClose?: CallBackFunction;
  onCancel?: CallBackFunction;
  onAccept?: CallBackFunction;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  onClose: CallBackFunction;
  onCancel: CallBackFunction;
  onAccept: CallBackFunction;
  cancelText: string;
  acceptText: string;
  title: string;
  confirmMessage: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GenericDialogData,
    public dialogRef: MatDialogRef<DialogComponent>
  ) {
    if (data) {
      const voidFunction = () => {};
      this.onClose = data.onClose || voidFunction;
      this.onCancel = data.onCancel || voidFunction;
      this.onAccept = data.onAccept || voidFunction;
      this.cancelText = data.cancelText;
      this.acceptText = data.acceptText;
      this.title = data.title;

      this.confirmMessage = Array.isArray(data.messages) ? data.messages : [data.messages];
      this.dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.onClose();
        });
    }
  }

  handleCancel() {
    this.onCancel();
  }

  handleAccept() {
    this.onAccept();
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isEqual } from 'lodash';

export function getStringValue(rawValue: any): string {
  if (rawValue == null) {
    return null;
  }

  // convert to string
  return rawValue + '';
}

export function capitalizeFirstLetter(text: string): string {
  return text
    ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    : '';
}

export function requiredValidator(): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const formValue: Record<string, string | boolean> = formGroup.getRawValue();
    return !Object.values(formValue).every(value => !!value || value === false) ? { required: true } : null;
  };
}

export function noChangesValidator(initialSettingValues: Record<string, string>): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const { useDefaultSettings, ...currentSettingsValues } = formGroup.getRawValue();
    return isEqual(currentSettingsValues, initialSettingValues) ? { noChanges: true } : null;
  };
}

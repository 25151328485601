import { Injectable } from '@angular/core';
import { DVCVariableSet, initializeDevCycle } from '@devcycle/js-client-sdk';
import { Observable, ReplaySubject } from 'rxjs';

import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private devCycleClient;
  private variables: DVCVariableSet;
  private variableSubject: ReplaySubject<DVCVariableSet> = new ReplaySubject();
  private isPreloaded = false;

  constructor() { }

  initialize(): void {
    const dvcUser = { isAnonymous: true };
    const clientId = environment.devCycle.clientId;
    this.devCycleClient = initializeDevCycle(clientId, dvcUser);
    this.devCycleClient.onClientInitialized().then(client => {
      this.isPreloaded = true;
      this.variables = client.allVariables();
      this.variableSubject.next(this.variables);
    });
    this.devCycleClient.subscribe('variableUpdated:*', (key, val) => {
      if (this.isPreloaded) {
        this.variables[key] = val;
        this.variableSubject.next(this.variables);
      }
    });
  }

  getVariables(): Observable<DVCVariableSet> {
    return this.variableSubject;
  }
}

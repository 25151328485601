// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details

export const permissions = {
  defaultRedirectUrl: '/',
  defaultPermPrefix: 'cts',
  routes: [['/', 'read:assets']],
};

export const appName = 'Company Settings';
export const appVersion = '0.0.1';

export const httpRetryCount = 3;

// Number of requests allowed to run concurrently at a time
export const ALLOWED_CONCURRENTLY_REQUESTS_NUMBER = 5;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { DVCVariableSet } from '@devcycle/js-client-sdk';

import { AppState } from '@src/app/app.state';
import { ThresholdSettingByNameMapper } from '@src/app/models/settings.model';
import { Category, CategoryView, Event, EventView, Setting, SettingView, Threshold, ThresholdView } from '@src/app/models/ui-layout.model';
import { environment } from '@src/environments/environment';
import { getStringValue } from '@src/app/utils/utils';
import { FeatureToggleService } from '@src/app/services/feature-toggle.service';

@Component({
  selector: 'app-threshold-settings',
  templateUrl: './threshold-settings.component.html',
  styleUrls: ['./threshold-settings.component.scss'],
})
export class ThresholdSettingsComponent implements OnInit, OnDestroy {
  @Select(AppState.getSettings) settingByNameMapper$: Observable<ThresholdSettingByNameMapper>;
  @Select(AppState.getConfigurationLayoutUI) configurationLayoutUI$: Observable<Category[]>;
  @Select(AppState.getCompanyId) companyId$: Observable<string>;

  displayedLayoutUI$: Observable<Category[]>;
  categoriesView$: Observable<CategoryView[]>;
  expandStates: Record<string, boolean> = {};

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private featureToggleService: FeatureToggleService) { }

  ngOnInit(): void {
    this.companyId$.pipe(
      takeUntil(this.destroy$),
      filter(company => !!company),
    ).subscribe(() => {
      this.expandStates = {};

      this.categoriesView$ = combineLatest([
        this.settingByNameMapper$.pipe(filter(result => !isEmpty(result))),
        this.displayedLayoutUI$,
      ]).pipe(
        takeUntil(this.destroy$),
        map(([settingByNameMapper, uiConfigurationLayouts]) => this.buildLayoutModelFromSettingNames(settingByNameMapper, uiConfigurationLayouts)),
      );
    });

    this.displayedLayoutUI$ = combineLatest([
      this.configurationLayoutUI$,
      this.featureToggleService.getVariables(),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([layouts, variables]) => this.buildDisplayedLayoutUI(layouts, variables))
    );
  }

  expansionChange(eventName: string, isExpanded: boolean) {
    this.expandStates[eventName] = !!isExpanded;
  }

  private buildLayoutModelFromSettingNames(thresholdSettingByNameMapper: ThresholdSettingByNameMapper, uiConfigurationLayouts: Category[]): CategoryView[] {
    return uiConfigurationLayouts.map(({ events, ...otherFields }): CategoryView => {
      const eventsView = events.map(({ name, thresholds, lastUserSettingName, ...otherFields }): EventView => {
        let lastUpdatedDate: string;
        const thresholdsView = thresholds.map(({ settings, ...otherFields }): ThresholdView => {
          const settingsView = settings.map(({ startDescription, endDescription, settingName }): SettingView => {
            const endDescriptionField = endDescription ? { endDescription } : {};
            const formControlField = this.buildFormControlFromSetting(thresholdSettingByNameMapper, settingName);
            const settingLastUpdatedDate = thresholdSettingByNameMapper[settingName]?.modified;
            if (!lastUpdatedDate || moment(settingLastUpdatedDate).isAfter(lastUpdatedDate)) {
              lastUpdatedDate = settingLastUpdatedDate;
            }

            return {
              startDescription,
              ...endDescriptionField,
              ...formControlField,
            };
          });

          return {
            ...otherFields,
            settingsView,
          };
        });

        return {
          ...otherFields,
          name,
          thresholdsView,
          isExpanded: !!this.expandStates[name],
          /**
           * TODO: Work with /user API to get user info (fullName, last time update) later.
           * Because value of setting_name_last_user is only userId.
           */
          updatedBy: '',
          // modified: lastUser?.modified,
          modified: lastUpdatedDate,
        };
      });

      return {
        ...otherFields,
        eventsView,
      };
    });
  }

  private buildFormControlFromSetting(thresholdSettingByNameMapper: ThresholdSettingByNameMapper, settingName: string) {
    const thresholdSetting = thresholdSettingByNameMapper[settingName] || null;

    return thresholdSetting
      ? {
          formControl: {
            id: thresholdSetting.id,
            key: settingName,
            type: thresholdSetting.type,
            value: getStringValue(thresholdSetting.value || thresholdSetting.defaultValue),
            defaultValue: getStringValue(thresholdSetting.defaultValue),
            options: thresholdSetting.options || [],
            validators: {}, // TODO: Implement validator logic later
          },
        }
      : {};
  }

  get alertPageUrl() {
    return environment.externalLinks.alertPage;
  }

  private buildDisplayedLayoutUI(data: (Category | Event | Threshold)[], dvcVariables: DVCVariableSet, level: number = 0) {
    const layoutUINextKeyByLevel = {
      0: 'events',
      1: 'thresholds',
      2: 'settings',
    };
    const nextKey = layoutUINextKeyByLevel[level];
    return data.reduce((acc, curr) => {
      const value = nextKey === 'settings'
        ? curr[nextKey].filter((x: Setting) => !dvcVariables[`cts-suppress-setting-${x.settingName}`]?.value)
        : this.buildDisplayedLayoutUI(curr[nextKey], dvcVariables, level + 1);
      if (value.length) {
        acc.push({ ...curr, [nextKey]: value });
      }
      return acc;
    }, []);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
